$(document).ready(() => {
    function collapseTile() {
        $('.card-tile__exp').unbind('click').on('click', (e) => {
            e.preventDefault();

            const self = $(this);
            const linkText = self.find('.exp__txt');
            const parentTile = self.parents('.exp__parent');

            if (parentTile.hasClass('exp__parent--active')) {
                parentTile.removeClass('exp__parent--active');
                linkText.text('Show Less');
            } else {
                parentTile.addClass('exp__parent--active');
                linkText.text('Show More');
            }
        });
    }

    collapseTile();

    // eslint-disable-next-line no-unused-vars
    function creditRange() {
        const creditDataPoints = [300, 350, 400, 450, 500, 550, 579, 580, 669, 670, 700, 740, 800, 850];
        const { length } = creditDataPoints;
        const creditRequirements = document.querySelectorAll('.card-tile__cred-req');
        let score;

        // eslint-disable-next-line array-callback-return
        Array.from(creditRequirements).map((creditReq) => {
            const dataScore = creditReq.querySelector('.cred--rating').getAttribute('data-rating').toLowerCase();

            if (dataScore === undefined) {
                return;
            }

            switch (dataScore) {
            case 'bad':
                score = [300, 579];
                break;
            case 'fair':
                score = [300, 669];
                break;
            case 'good':
                score = [670, 850];
                break;
            case 'excellent':
                score = [740, 850];
                break;
            default:
                return;
            }

            creditDataPoints.map((point) => {
                const bar = creditReq.querySelector('.cred__bar');
                const step = document.createElement('div');
                const stepValue = document.createElement('span');
                const width = `${(100 / length).toFixed(2)}%`;
                const lowScore = score[0];
                const highScore = score[1];

                step.setAttribute('class', 'item');
                step.setAttribute('data-point', point);
                step.style.width = width;
                stepValue.textContent = point;

                if (point >= lowScore && point <= highScore) {
                    step.classList.add('item--active');

                    if (point === lowScore) {
                        step.classList.add('item--start');
                    }
                    if (point === highScore) {
                        step.classList.add('item--end');
                    }
                }
                bar.appendChild(step);
                step.appendChild(stepValue);
                return true;
            });
        });
    }

    /* Function to add Read more to spice box */
    function truncateSpiceBox() {
        if (window.matchMedia('only screen and (max-width: 454px)').matches) {
            const ps = document.querySelectorAll('div.spice_desc .rich-text');

            for (const el of ps) {
                if (el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth) {
                    // your element has overflow and truncated
                    // show read more / read less button
                    el.parentElement.classList.add('truncated');
                }
            }
        }
    }

    /* on page load add the read more to spice boxes */
    truncateSpiceBox();

    /* on resizing the window, add read more to spice boxes */
    window.addEventListener('resize', truncateSpiceBox);
});
