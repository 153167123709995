$(document).ready(() => {
    function collapseTile() {
        $('.card-tile__exp').unbind('click').on('click', (e) => {
            e.preventDefault();

            const self = $(this);
            const linkText = self.find('.exp__txt');
            const parentTile = self.parents('.exp__parent');

            if (parentTile.hasClass('exp__parent--active')) {
                parentTile.removeClass('exp__parent--active');
                linkText.text('Show Less');
            } else {
                parentTile.addClass('exp__parent--active');
                linkText.text('Show More');
            }
        });
    }

    collapseTile();

    /* Function to add Read more to spice box */
    function truncateSpiceBox() { // eslint-disable-line no-unused-vars
        if (window.matchMedia('only screen and (max-width: 454px)').matches) {
            const ps = document.querySelectorAll('div.spice-desc .rich-text');

            for (const el of ps) {
                if (el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth) {
                    // your element has overflow and truncated
                    // show read more / read less button
                    el.parentElement.classList.add('truncated');
                }
            }
        }
    }

    // The below code was commented out on March 14, 2025 to stop truncating the spice box
    // Leaving the rest of the code for now in-case we change our mind in the future.

    // /* on page load add the read more to spice boxes */
    // truncateSpiceBox();
    //
    // /* on resizing the window, add read more to spice boxes */
    // window.addEventListener('resize', truncateSpiceBox);
});
